export const BASEURL2 = "https://hammerhead-app-p5uvi.ondigitalocean.app/";
export const ANALYTICS_KEY = "SCK_7CF9F85A33FF0A188FCD8B";
export const COLLECTION_NAME = "qabrityavp";
export const INDEX_NAME = "xyrcrtrggs";
export const ASSISTANT_ID = "AST_D23C06BB447E918";

export const META_DATA = {
  collection_name: COLLECTION_NAME,
  index_name: INDEX_NAME,
  assistant_id: ASSISTANT_ID,
};

export const DEPT = [
  {
    name: "Customer Service",
    phone: "+2348110248204",
    email: "sotom.horsfall@analyticsintelligence.com",
  },
];
